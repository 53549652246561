// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//Home Page
const greeting = {
  title: "Arpit Jaiswal",
  logo_name: "Arpit Jaiswal",
  nickname: "MERN Stack Developer",
  subTitle:
    "An aspiring developer who loves to take on new projects that challenges his analytical and technological capacities.",
  resumeLink:
    "https://drive.google.com/file/d/18yd7bABOHG6HAzzsbLUYyY7h7IpejgHA/view?usp=sharing",
  portfolio_repository: "https://github.com/arpit-ayeeee",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/arpit-ayeeee",
    fontAwesomeIcon: "fa-github", 
    backgroundColor: "#181717",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/arpit28",
    fontAwesomeIcon: "fa-linkedin-in", 
    backgroundColor: "#0077B5",
  },
  {
    name: "Gmail",
    link:
      " https://mail.google.com/mail/u/?authuser=arpitjaiswal282000@gmail.com",
    fontAwesomeIcon: "fa-google", 
    backgroundColor: "#D14836", 
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/arpitjaiswal282000",
    fontAwesomeIcon: "fa-facebook-f", 
    backgroundColor: "#1877F2",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/arpit.ayeeeeeeeee",
    fontAwesomeIcon: "fa-instagram", 
    backgroundColor: "#E4405F", 
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development (MERN)",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website front end using React JS",
        "⚡ Building backend using Express JS",
        "⚡ Providing no-sql database using MongoDB",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Redux",
          fontAwesomeClassname: "simple-icons:redux",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
  ],
};

const techStack = {
  viewSkillBars: true, 
  experience: [
    {
      Stack: "Front-End", 
      progressPercentage: "80%", 
    },
    {
      Stack: "Back-End",
      progressPercentage: "50%",
    },
    {
      Stack: "Programming",
      progressPercentage: "75%",
    },
    {
      Stack: "Design",
      progressPercentage: "35%",
    },
  ],
};

//Language Skill
const sLanguage = {
  viewSkillBars: true,
  experience: [
    {
      Stack: "C/C++",
      progressPercentage: "90%",
    },
    {
      Stack: "HTML", 
      progressPercentage: "80%", 
    },
    {
      Stack: "CSS",
      progressPercentage: "80%",
    },
    {
      Stack: "JavaScript",
      progressPercentage: "60%",
    },
    {
      Stack: "React",
      progressPercentage: "75%",
    },
    {
      Stack: "ExpressJS",
      progressPercentage: "60%",
    },
    {
      Stack: "NodeJS",
      progressPercentage: "50%",
    },
    {
      Stack: "MongoDB",
      progressPercentage: "50%",
    },
    {
      Stack: "SQL",
      progressPercentage: "50%",
    },
    {
      Stack: "Bootstrap",
      progressPercentage: "80%",
    },
  ],
};

//Tools Skill
const tools = {
  viewSkillBars: true,
  experience: [
    {
      Stack: "Visual Studio Code", 
      progressPercentage: "85%", 
    },
    {
      Stack: "Google Docs",
      progressPercentage: "90%",
    },
    {
      Stack: "Firebase", 
      progressPercentage: "60%", 
    },
    {
      Stack: "Github",
      progressPercentage: "80%",
    },
    {
      Stack: "Chrome Dev Tool",
      progressPercentage: "80%",
    },
    {
      Stack: "Postman",
      progressPercentage: "80%",
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/arpit_aye",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@arpitjaiswal282000",
    },
    {
      siteName: "GeeksForGeeks",
      iconifyClassname: "simple-icons:geeksforgeeks",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.geeksforgeeks.org/user/arpitjaiswal282000",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "BMS Institute of Technology & Management, Bengaluru",
      subtitle: "B.E. in Information Science and Engineering",
      logo_path: "bms.jpg",
      alt_name: "",
      duration: "August 2018 - August 2022",
      cgpa: "8.18 / 10.00",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, OOPS, DBMS, OS, CN, DMS etc.",
        "⚡ Apart from this, I have done course on Full Stack Development.",
      ],
      website_link: "https://bmsit.ac.in",
    },
    {
      title: "Pragati Public Senior Secondary School, Kota",
      subtitle: "Higher Secondary School (12th)",
      logo_path: "cbse.jpg",
      alt_name: "",
      duration: "May 2016 - May 2018",
      cgpa: "7.50 / 10.00",
      descriptions: [
        "⚡ I have studied Physics, Chemistry, Maths, English and Physical Education",
      ],
      website_link: "https://www.pragatischool.edu.in/index1.php",
    },
    {
      title: "Christukula Mission Higher Secondary School",
      subtitle: "High School",
      logo_path: "icse.jpg",
      alt_name: "",
      duration: "Till May 2016",
      cgpa: "8.50 / 10.00",
      descriptions: [
      ],
      website_link: "https://christukulamissionschool.com",
    }
  ],
};

const certifications = {
  certifications: [
    {
      title: "HTML,CSS, and Javascript for Web Developers",
      subtitle: "Yaakov Chaikin",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/41e9b767f8f233b9fafb059a6dc41238",
      alt_name: "John Hopkins University",
      color_code: "#2A73CC",
    },
    {
      title: "Front-End Web UI Frameworks and Tools: Bootstrap 4",
      subtitle: "Jogesh Muppalla",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/d2ba1ae7534cb43bb32471cccac2d3ee",
      alt_name: "Hong Kong Univerty",
      color_code: "#2A73CC",
    },
    {
      title: "Front-End Web Development with React",
      subtitle: "Jogesh Muppalla",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/014e56cecd0310ae44e4fe7aa5ce67f5",
      alt_name: "Hong Kong Univerty",
      color_code: "#2A73CC",
    },
    {
      title: "Server-side Development with NodeJS, Express and MongoDB",
      subtitle: "Jogesh Muppalla",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/f258f55910ca4b90e467ca2730cd7eeb",
      alt_name: "Hong Kong Univerty",
      color_code: "#2A73CC",
    },
    {
      title: "Mordern Javascript (ES6 Basics)",
      subtitle: "Stanimera Vlaeva",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/70d2e14227f13f0dc8a055489ee506bc",
      alt_name: "Google Developer Expret",
      color_code: "#2A73CC",
    },
    {
      title: "Introduction to Environmental Law and Policy",
      subtitle: "Don Hornstien",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/18d97fdc950feb7e0f5e915e331dac87",
      alt_name: "The University of North Carolina at Chapel Hill",
      color_code: "#2A73CC",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Internship",
  description:
    "I am an under-graduate with experience gained from voluntary works on sites like stackoverflow, github.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Internships",
      experiences: [
        {
          title: "Front-End Development Intern",
          company: "Fitnesstraker Pvt. Ltd.",
          company_url: "https://www.fitnesstraker.com/",
          logo_path: "fitnesstraker_logo.png",
          duration: "Sept 2020 - Dec 2020",
          location: "Bengaluru, Karnataka",
          description:
            "Designed the website’s mockup for company’s product(app) using Figma. Single-handedly developed dynamic and user-friendly website for product from scratch. Implemented enhancements that improved web responsiveness.",
          color: "#ee3c26",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Web Development projects and deploy them to web applications using third part hosting service and PAAS. Stil trying to gain experience by trying my hands on different projects. Most of these are academic projects with some personal projects in between.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "arpit.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with Basic Programming,Full Stack Development (MERN).",
  },
  addressSection: {
    title: "Address",
    subtitle: "Yelahanka, Bengaluru, Karnataka",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://g.page/RamkyOneNorth?share",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "6266066700",
  },
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  techStack,
  sLanguage,
  tools,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
